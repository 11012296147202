<template>
  <div class="sectionContainer">
    <button class="tableButton" @click="loadNext" v-if="showAmount <= 16">
      next 5 items
    </button>
    <button class="tableButton" @click="loadBack" v-if="showAmount > 5">
      back 5 items
    </button>
    <ProfitablilityTable :showAmount="showAmount"></ProfitablilityTable>
  </div>
</template>

<script>
// @ is an alias to /src
import { ref } from "vue";
import ProfitablilityTable from "@/components/ProfitablilityTable.vue";
export default {
  name: "ProfitabilityAnalysis",
  components: {
    ProfitablilityTable,
  },
  setup() {
    const showAmount = ref(1);
    function loadNext() {
      showAmount.value = showAmount.value + 5;
    }
    function loadBack() {
      showAmount.value = showAmount.value - 5;
    }
    return {
      loadNext,
      loadBack,
      showAmount,
    };
  },
};
</script>
