<template>
  <div class="divTable greenTable greenTable--profitability">
    <div class="divTableHeading">
      <div class="divTableRow">
        <div class="divTableHead divTableHead--white cellContent-center">
          Year
        </div>
        <div class="divTableHead divTableHead--white">{{ showAmount }}</div>
        <div class="divTableHead divTableHead--white">{{ showAmount + 1 }}</div>
        <div class="divTableHead divTableHead--white">{{ showAmount + 2 }}</div>
        <div class="divTableHead divTableHead--white">{{ showAmount + 3 }}</div>
        <div class="divTableHead divTableHead--white">{{ showAmount + 4 }}</div>
      </div>
    </div>
    <div class="divTableBody">
      <div class="divTableRow">
        <div class="divTableIDS divTableIDS--grey cellContent-left">
          Production in kWh
        </div>
        <div class="divTableCell" v-for="e in erzeugung" v-bind:key="e">
          {{ formatNum(e) }}
        </div>
      </div>
      <div class="divTableRow">
        <div class="divTableIDS divTableIDS--grey cellContent-left">
          Revenue p.a.
        </div>
        <div
          class="divTableCell divTableCell--bold"
          v-for="e in umsatz"
          v-bind:key="e"
        >
          {{ formatNum(e) }}
        </div>
      </div>
      <div class="divTableRow"><div class="divTableCell"></div></div>
      <div class="divTableRow">
        <div class="divTableIDS divTableIDS--grey cellContent-left">OPEX</div>
        <div
          class="divTableCell divTableCell--bold"
          v-for="e in opex"
          v-bind:key="e"
        >
          {{ formatNum(e) }}
        </div>
      </div>
      <div class="divTableRow">
        <div class="divTableIDS divTableIDS--bold cellContent-center">
          Fixed O&M
        </div>
      </div>
      <div class="divTableRow">
        <div class="divTableIDS cellContent-right">Maintenance</div>
        <div class="divTableCell" v-for="e in wartungsvertrag" v-bind:key="e">
          {{ formatNum(e) }}
        </div>
      </div>
      <div class="divTableRow">
        <div class="divTableIDS cellContent-right">land lease</div>
        <div class="divTableCell" v-for="e in pacht" v-bind:key="e">
          {{ formatNum(e) }}
        </div>
      </div>
      <div class="divTableRow">
        <div class="divTableIDS cellContent-right">Insurance</div>
        <div class="divTableCell" v-for="e in versicherungen" v-bind:key="e">
          {{ formatNum(e) }}
        </div>
      </div>
      <div class="divTableRow">
        <div class="divTableIDS divTableIDS--bold cellContent-center">
          Variable O&M
        </div>
      </div>
      <div class="divTableRow">
        <div class="divTableIDS cellContent-right">
          (unscheduled maintenance)
        </div>
        <div class="divTableCell" v-for="e in auInstandhaltung" v-bind:key="e">
          {{ formatNum(e) }}
        </div>
      </div>
      <div class="divTableRow">
        <div class="divTableIDS cellContent-right">Accruals</div>
        <div class="divTableCell" v-for="e in rueckstellungen" v-bind:key="e">
          {{ formatNum(e) }}
        </div>
      </div>
      <div class="divTableRow">
        <div class="divTableIDS divTableIDS--blue cellContent-left">EBITDA</div>
        <div
          class="divTableCell divTableCell--bold"
          v-for="e in ebitda"
          v-bind:key="e"
        >
          {{ formatNum(e) }}
        </div>
      </div>
      <div class="divTableRow">
        <div class="divTableIDS divTableIDS--bold cellContent-left">CAPEX</div>
      </div>
      <div class="divTableRow">
        <div class="divTableIDS cellContent-center">Depreciation</div>
        <div class="divTableCell" v-for="e in linAbschr" v-bind:key="e">
          {{ formatNum(e) }}
        </div>
      </div>
      <div class="divTableRow">
        <div class="divTableIDS cellContent-center">Book Value</div>
        <div class="divTableCell" v-for="e in restBuchwert" v-bind:key="e">
          {{ formatNum(e) }}
        </div>
      </div>
      <div class="divTableRow">
        <div class="divTableIDS cellContent-center">Amortisation</div>
        <div class="divTableCell" v-for="e in kredittilgung" v-bind:key="e">
          {{ formatNum(e) }}
        </div>
      </div>
      <div class="divTableRow">
        <div class="divTableIDS cellContent-center">Interest charges</div>
        <div class="divTableCell" v-for="e in zinslast" v-bind:key="e">
          {{ formatNum(e) }}
        </div>
      </div>
      <div class="divTableRow">
        <div class="divTableIDS cellContent-center">Capital</div>
        <div class="divTableCell" v-for="e in kapital" v-bind:key="e">
          {{ formatNum(e) }}
        </div>
      </div>
      <div class="divTableRow">
        <div class="divTableIDS cellContent-center">Balance</div>
        <div class="divTableCell" v-for="e in saldo" v-bind:key="e">
          {{ formatNum(e) }}
        </div>
      </div>

      <div class="divTableRow">
        <div class="divTableIDS divTableIDS--blue cellContent-left">EBT</div>
        <div
          class="divTableCell divTableCell--bold"
          v-for="e in ebt"
          v-bind:key="e"
        >
          {{ formatNum(e) }}
        </div>
      </div>
      <div class="divTableRow"><div class="divTableCell"></div></div>
      <div class="divTableRow">
        <div class="divTableIDS divTableIDS--bold cellContent-left">
          TAX expenditure
        </div>
        <div
          class="divTableCell divTableCell--bold"
          v-for="e in steuerAufwendung"
          v-bind:key="e"
        >
          {{ formatNum(e) }}
        </div>
      </div>
      <div class="divTableRow">
        <div class="divTableIDS">Corporate tax</div>
        <div
          class="divTableCell"
          v-for="e in koerperschaftsSteuer"
          v-bind:key="e"
        >
          {{ formatNum(e) }}
        </div>
      </div>
      <div class="divTableRow">
        <div class="divTableIDS">Property tax</div>
        <div class="divTableCell" v-for="e in gewerbeSteuer" v-bind:key="e">
          {{ formatNum(e) }}
        </div>
      </div>
      <div class="divTableRow">
        <div class="divTableIDS divTableIDS--blue cellContent-left">Profit</div>
        <div
          class="divTableCell divTableCell--bold"
          v-for="e in profit"
          v-bind:key="e"
        >
          {{ formatNum(e) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, toRefs } from "vue";
import { useStore } from "vuex";
import { formatNum } from "../helper/formatNumber";
export default {
  name: "ProfitablilityTable",
  props: {
    showAmount: Number,
  },
  setup(props) {
    const refs = toRefs(props);
    const store = useStore();
    const erzeugung = ref([0, 0, 0, 0, 0]);
    const umsatz = ref([0, 0, 0, 0, 0]);
    const opex = ref([0, 0, 0, 0, 0]);
    const wartungsvertrag = ref([0, 0, 0, 0, 0]);
    const pacht = ref([0, 0, 0, 0, 0]);
    const versicherungen = ref([0, 0, 0, 0, 0]);
    const rueckstellungen = ref([0, 0, 0, 0, 0]);
    const auInstandhaltung = ref([0, 0, 0, 0, 0]);
    const ebitda = ref([0, 0, 0, 0, 0]);
    const calculation = computed(() => store.getters.calculation);
    const ebt = ref([0, 0, 0, 0, 0]);
    const kapital = ref([0, 0, 0, 0, 0]);
    const zinslast = ref([0, 0, 0, 0, 0]);
    const saldo = ref([0, 0, 0, 0, 0]);
    const kredittilgung = ref([0, 0, 0, 0, 0]);
    const restBuchwert = ref([0, 0, 0, 0, 0]);
    const linAbschr = ref([0, 0, 0, 0, 0]);
    const koerperschaftsSteuer = ref([0, 0, 0, 0, 0]);
    const gewerbeSteuer = ref([0, 0, 0, 0, 0]);
    const steuerAufwendung = ref([0, 0, 0, 0, 0]);
    const profit = ref([0, 0, 0, 0, 0]);
    function calculateRows() {
      if (typeof calculation.value.profAnalysis !== "undefined") {
        for (
          let i = refs.showAmount.value;
          i < refs.showAmount.value + 5;
          i++
        ) {
          erzeugung.value[i - refs.showAmount.value] =
            calculation.value.profAnalysis[i].erzeugung;
          umsatz.value[i - refs.showAmount.value] =
            Math.round(calculation.value.profAnalysis[i].umsatz * 100) / 100;
          wartungsvertrag.value[i - refs.showAmount.value] =
            calculation.value.profAnalysis[i].wartungsvertrag;
          pacht.value[i - refs.showAmount.value] =
            calculation.value.profAnalysis[i].pacht;
          versicherungen.value[i - refs.showAmount.value] =
            calculation.value.profAnalysis[i].versicherungen;
          rueckstellungen.value[i - refs.showAmount.value] =
            calculation.value.profAnalysis[i].rueckstellungen;
          auInstandhaltung.value[i - refs.showAmount.value] =
            calculation.value.profAnalysis[i].ausserplanInstandhaltung;
          opex.value[i - refs.showAmount.value] =
            calculation.value.profAnalysis[i].OPEX;
          ebitda.value[i - refs.showAmount.value] =
            Math.round(calculation.value.profAnalysis[i].EBITDA * 100) / 100;
          linAbschr.value[i - refs.showAmount.value] =
            calculation.value.profAnalysis[i].linAbschreibung;
          restBuchwert.value[i - refs.showAmount.value] =
            calculation.value.profAnalysis[i].restBuchwert;
          kredittilgung.value[i - refs.showAmount.value] =
            Math.round(calculation.value.profAnalysis[i].kreditTilgung * 100) /
            100;
          saldo.value[i - refs.showAmount.value] =
            Math.round(calculation.value.profAnalysis[i].Saldo * 100) / 100;
          zinslast.value[i - refs.showAmount.value] =
            Math.round(calculation.value.profAnalysis[i].zinslast * 100) / 100;
          kapital.value[i - refs.showAmount.value] =
            Math.round(calculation.value.profAnalysis[i].kapital * 100) / 100;
          ebt.value[i - refs.showAmount.value] =
            Math.round(calculation.value.profAnalysis[i].EBT * 100) / 100;
          koerperschaftsSteuer.value[i - refs.showAmount.value] =
            Math.round(
              calculation.value.profAnalysis[i].koerperschaftsSteuer * 100
            ) / 100;
          gewerbeSteuer.value[i - refs.showAmount.value] =
            Math.round(calculation.value.profAnalysis[i].gewerbeSteuer * 100) /
            100;
          steuerAufwendung.value[i - refs.showAmount.value] =
            Math.round(
              calculation.value.profAnalysis[i].steuerAufwendung * 100
            ) / 100;
          profit.value[i - refs.showAmount.value] =
            Math.round(calculation.value.profAnalysis[i].profit * 100) / 100;
        }
      }
    }
    watch(calculation, () => {
      if (calculation.value.airDensityCalculor) {
        calculateRows();
      }
    });
    watch(refs.showAmount, () => {
      if (calculation.value.airDensityCalculor) {
        calculateRows();
      }
    });
    if (calculation.value.airDensityCalculor) {
      calculateRows();
    }
    return {
      formatNum,
      erzeugung,
      umsatz,
      opex,
      wartungsvertrag,
      pacht,
      versicherungen,
      rueckstellungen,
      auInstandhaltung,
      ebitda,
      ebt,
      kapital,
      zinslast,
      linAbschr,
      saldo,
      kredittilgung,
      restBuchwert,
      gewerbeSteuer,
      koerperschaftsSteuer,
      steuerAufwendung,
      profit,
    };
  },
};
</script>

<style></style>
